<script lang="ts">
	import { page } from '$app/stores'
	import MobileNavigation from './MobileNavigation.svelte'
	import TopNavigation from './TopNavigation.svelte'
	import state from '$lib/stores/auth-state'
	import { signOut } from '$lib/authentication/firebase'
	import modal from '$lib/stores/modal-state'
	import { goto } from '$app/navigation'
	import { getContextClient } from '@urql/svelte'
	import {
		InsertTripDocument,
		type InsertTripMutation,
		type InsertTripMutationVariables,
	} from '$lib/queries/generated/InsertTrip'
	import { loginAndInsertTrip, clearInsertTripOnLoadParams } from '../../../utils/insertTrip'
	import { performSignIn } from '$lib/authentication/helpers'
	import Button from '../buttons/Button.svelte'
	import { track } from '$src/utils/track'
	import Avatar from '../profiles/Avatar.svelte'

	$: bannerFeatureEnabled = $page.data.banner
	$: isBannerBlocked = $page.data.blockBanner
	$: isSignedIn = $state.signedIn
	$: user = $state.currentUser
	$: avatarSrc =
		user?.currentProfilePictureUrl && user?.currentProfilePictureUrl != ''
			? user?.currentProfilePictureUrl
			: '/visuals/icons/default-user.svg'

	let navType: NavType = null

	const client = getContextClient()
	const insertTripMutation = (vars: InsertTripMutationVariables) =>
		client
			.mutation<InsertTripMutation, InsertTripMutationVariables>(InsertTripDocument, vars)
			.toPromise()

	function handleStartTripClick() {
		track('Start Trip Clicked')
		if ($state.signedIn) {
			handleInsertTrip()
		} else {
			loginAndInsertTrip(
				performSignIn,
				() => handleInsertTrip().then(clearInsertTripOnLoadParams),
				null,
			)

			return Promise.resolve()
		}
	}

	const handleNavOpen = (event: CustomEvent<NavType>) => {
		navType = event.detail
	}

	function setClose() {
		navType = null
	}

	function handleSignOut(event: MouseEvent) {
		event.preventDefault()
		track('Sign Out Clicked')
		signOut()
	}

	function handleSignIn(event: MouseEvent) {
		event.preventDefault()
		track('Sign In Clicked')
		modal.open('signup', { intent: 'signIn' })
	}

	async function handleInsertTrip() {
		insertTripMutation({ input: { title: 'New Trip' } })
			.then((res) => {
				if (res?.data?.insertTrip?.id) {
					goto(`/trips/${res?.data?.insertTrip?.id}`)
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const openMailer = () => {
		track('Open Mailer', { category: 'Footer' })
		// chatLoader.loadChat({ open: true })
		// var email = 'support@airheart.com'
		// var subject = encodeURIComponent('Requesting your help')
		// var body = encodeURIComponent('Describe your problem...')

		// var mailtoLink = 'mailto:' + email + '?subject=' + subject + '&body=' + body
		// window.location.href = mailtoLink
		window.open('https://intercom.help/airheart/en', '_blank')
	}

	function handleNavigate(event: MouseEvent, target: string) {
		event.preventDefault()
		track('Navigation Clicked', { target })
		goto(target).then(() => {
			setClose()
		})
	}
</script>

<MobileNavigation isOpen={navType === 'mobile'} on:close={setClose}>
	<nav class="grid gap-y-4">
		<button
			on:click={(e) => handleNavigate(e, '/explore')}
			class="px-4 text-base font-medium tracking-tight text-start">Explore Guides</button
		>
		{#if isSignedIn}
			<button
				on:click={(e) => handleNavigate(e, '/trips')}
				class="px-4 text-base font-medium tracking-tight text-start">Your Trips</button
			>
			<button
				on:click={(e) => handleNavigate(e, '/store')}
				class="px-4 text-base font-medium tracking-tight text-start">Your Store</button
			>
		{/if}
		<div class="h-[1px] bg-brand-gray-4/25 mt-2" />

		<button
			on:click={(e) => openMailer}
			class="px-4 text-base font-medium tracking-tight text-start">Get Help</button
		>
		<button
			on:click={(e) => handleNavigate(e, '/guide')}
			class="px-4 text-base font-medium tracking-tight text-start">Sell Your Guides</button
		>
		<button
			on:click={(e) => handleNavigate(e, '/about')}
			class="px-4 text-base font-medium tracking-tight text-start">About Us</button
		>

		<Button on:click={handleStartTripClick} theme="brand" class="mt-2" size="sm"
			>Start a Trip</Button
		>
	</nav>
	<div class="px-5 mt-6 pt-6 space-y-6">
		{#if isSignedIn}
			<div class="px-4 py-4 text-center select-none">
				<Avatar size="lg" src={avatarSrc} alt={user?.currentName} />
				<div class="mt-2 leading-4">{user?.currentName}</div>
				<div class="m-0 mt-1 text-xs leading-4 text-brand-secondary">{user?.email}</div>
			</div>
			<div class="text-center">
				<button
					aria-label="Sign out"
					class="text-black dark:text-white focus:outline-none hover:text-opacity-80"
					on:click={handleSignOut}
				>
					Sign Out
				</button>
			</div>
		{:else}
			<div class="flex flex-col space-y-4 w-full justify-center items-center">
				<p class="text-base font-medium text-center text-gray-900">
					<button
						aria-label="Sign in"
						on:click={handleSignIn}
						class="text-black dark:text-white focus:outline-none hover:text-opacity-80"
					>
						Sign In
					</button>
				</p>
			</div>
		{/if}
	</div>
</MobileNavigation>

<TopNavigation on:open={handleNavOpen} on:startTrip={handleStartTripClick} />

<div class={`lg:bg-white ${bannerFeatureEnabled && !isBannerBlocked ? 'lg:h-32' : 'lg:h-16'}`}>
	&nbsp;
</div>
