<script lang="ts">
	// import chatLoader from '$lib/chatloader/context'
	import type { CurrentUser } from '$lib/stores/auth-state'
	import { Menu, MenuButton, MenuItems, Transition } from '@rgossiaux/svelte-headlessui'
	import { createEventDispatcher } from 'svelte'
	import Item from './Item.svelte'
	import Avatar from '../profiles/Avatar.svelte'
	import HelpChatIcon from '../icons/HelpChatIcon.svelte'
	import LogoutIcon from '../icons/LogoutIcon.svelte'
	import ShopIcon from '../icons/ShopIcon.svelte'
	import { track } from '$src/utils/track'
	import { goto } from '$app/navigation'
	import CompassIcon from '../icons/CompassIcon.svelte'
	import LocationPinIcon from '../icons/LocationPinIcon.svelte'

	let className = ''
	export { className as class }
	export let embedded = false
	export let user: CurrentUser | null = null
	$: avatarSrc =
		user.currentProfilePictureUrl && user.currentProfilePictureUrl != ''
			? user.currentProfilePictureUrl
			: '/visuals/icons/default-user.svg'

	const dispatch = createEventDispatcher()
	function handleSignOut() {
		dispatch('signout')
	}

	// const openMailer = () => {
	// 	track('Help Clicked')
	// 	// chatLoader.loadChat({ open: true })
	// 	var email = 'support@airheart.com'
	// 	var subject = encodeURIComponent('Requesting your help')
	// 	var body = encodeURIComponent('Describe your problem...')

	// 	var mailtoLink = 'mailto:' + email + '?subject=' + subject + '&body=' + body
	// 	window.location.href = mailtoLink
	// }

	const openMailer = () => {
		track('Open Mailer', { category: 'Footer' })
		// chatLoader.loadChat({ open: true })
		// var email = 'support@airheart.com'
		// var subject = encodeURIComponent('Requesting your help')
		// var body = encodeURIComponent('Describe your problem...')

		// var mailtoLink = 'mailto:' + email + '?subject=' + subject + '&body=' + body
		// window.location.href = mailtoLink
		window.open('https://intercom.help/airheart/en', '_blank')
	}

	const openStore = () => {
		track('Store Clicked')
		goto('/store')
	}

	$: name = user?.currentName || ''
</script>

<Menu class={className}>
	<MenuButton
		class="flex items-center m-0 text-right flex-shrink-0 outline-none cursor-pointer select-none rounded-md focus:outline-none"
	>
		<Avatar size="md" src={avatarSrc} alt={user.currentName} />
	</MenuButton>
	<Transition
		class="relative z-50 block"
		enter="transition ease-out duration-100"
		enterFrom="transform opacity-0 scale-95"
		enterTo="transform opacity-100 scale-100"
		leave="transition ease-in duration-75"
		leaveFrom="transform opacity-100 scale-100"
		leaveTo="transform opacity-0 scale-95"
	>
		<MenuItems static={embedded} class="outline-none">
			<div
				class={`${
					embedded
						? 'w-full bg-transparent pt-8'
						: 'py-2 top-2 w-48 shadow-2xl rounded-md ring-1 ring-black ring-opacity-5'
				} origin-top-right absolute right-0 text-black bg-white divide-y divide-gray-200 z-[4999]`}
			>
				<div class="px-4 py-4 text-center select-none">
					<Avatar size="lg" src={avatarSrc} alt={name} />
					<div class="mt-2 leading-4">{name ?? 'No name'}</div>
					<div class="m-0 mt-1 text-xs leading-4 text-brand-primary truncate text-ellipsis">
						{user.email}
					</div>
				</div>
				<div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
					<Item
						on:click={() => goto('/trips')}
						label="Your Trips"
						class="text-black dark:text-black py-2 px-6"
					>
						<LocationPinIcon class="h-4 w-4 text-black" /></Item
					>
					<Item
						on:click={() => goto('/explore')}
						label="Explore Guides"
						class="text-black dark:text-black py-2 px-6"
					>
						<CompassIcon class="h-4 w-4 text-black" /></Item
					>
					<Item
						on:click={openStore}
						label="Your Store"
						class="text-black dark:text-black py-2 px-6"
					>
						<ShopIcon class="h-4 w-4" />
					</Item>
					<Item on:click={openMailer} label="Get Help" class="text-black dark:text-black py-2 px-6">
						<HelpChatIcon class="h-3 w-3 mx-[2px] text-black" /></Item
					>
				</div>
				<div class="pt-1">
					<Item
						on:click={handleSignOut}
						label="Log Out"
						class="text-black dark:text-black py-2 px-6"><LogoutIcon class="h-4 w-4" /></Item
					>
				</div>
			</div>
		</MenuItems>
	</Transition>
</Menu>
