<script lang="ts">
	import { MenuItem } from '@rgossiaux/svelte-headlessui'
	import { override } from '$src/utils/classnames'
	import { twMerge } from 'tailwind-merge'

	export let label = ''
	let className: string = ''
	export { className as class }

	const sharedClasses =
		'flex items-center px-4 py-2 w-full cursor-pointer bg-transparent focus:outline-none text-sm space-x-4 group transition-all font-medium text-black hover:bg-brand-gray-2 dark:text-white dark:hover:bg-brand-gray-4 dark:hover:bg-opacity-20'

	$: computedClassName = twMerge(override(sharedClasses, className))
</script>

<MenuItem on:click>
	<span class={computedClassName}>
		<slot />
		<div class="leading-4">{label}</div>
	</span>
</MenuItem>
