<script lang="ts">
	import MainFooter from '$lib/components/footers/MainFooter.svelte'
	import MainNavigation from '$lib/components/navigation/MainNavigation.svelte'
	import '../../app.css'
	import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
	import { setContextClient } from '@urql/svelte'
	import Modals from '$lib/components/modals/modals.svelte'
	import { page } from '$app/stores'
	import SvelteSeo from 'svelte-seo'
	import YoloAuth from '$src/lib/components/auth/YoloAuth.svelte'
	import GoogleTagManager from '$lib/components/analytics/GoogleTagManager.svelte'
	import FullStory from '$lib/components/analytics/FullStory.svelte'
	import SimpleAnalytics from '$lib/components/analytics/SimpleAnalytics.svelte'

	$: title = $page?.data?.title
	$: description = $page?.data?.description

	$: images = [
		{
			url: 'https://airheart.com/airheart-social.png',
			width: 1200,
			height: 600,
			alt: title,
		},
	]

	setContextClient(normalizedClient)
</script>

<svelte:head>
	<link rel="shortcut icon" href="/favicon.ico" />
	<meta name="theme-color" content="#FF006E" />
	<title>{$page?.data?.title}</title>
	<meta name="description" content={$page?.data?.description} />
</svelte:head>

<div class="min-h-dvh bg-white -mb-[8px]" data-sveltekit-preload-data>
	<SvelteSeo
		title={$page?.data?.title}
		jsonLd={{
			'@type': 'Corporation',
			logo: 'https://airheart.com/airheart-logo-full-new.svg',
			url: 'https://airheart.com',
			name: 'Airheart, Inc.',
			sameAs: [
				'https://twitter.com/AirheartInc',
				'https://instagram.com/airheartinc',
				'https://www.linkedin.com/company/airheartinc',
				'https://www.facebook.com/AirheartInc',
			],
		}}
		twitter={{
			description,
			title,
			image: 'https://airheart.com/marketing/marketing/airheart-social.png',
			imageAlt: title,
			site: '@airheartinc',
			card: 'summary_large_image',
		}}
		openGraph={{
			title,
			images,
			description,
		}}
	/>

	<MainNavigation />
	<div class="bg-white">
		<slot />
	</div>
	<MainFooter />
</div>

<Modals />
<YoloAuth />
<GoogleTagManager />
<FullStory />
<!-- <SimpleAnalytics /> -->
